import { styled } from 'styled-components';

export interface IDividerProps {
  text?: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.black70};
  font-size: 16px;
  line-height: 24px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.black70};
`;

const Divider = ({ text, ...rest }: IDividerProps) => {
  return (
    <Container role="separator" {...rest}>
      <Line />
      <Text>{text}</Text>
      <Line />
    </Container>
  );
};

export { Divider };
